import ExportModal from "components/base/ExportModal";
import { KTIcon } from "helpers";
import { Fragment, useEffect, useRef, useState } from "react";
import CardHeader from "components/cards/CardHeader";
import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import Table from "components/table/Table";
import TheadRow from "components/table/TheadRow";
import { deleteUser, resendUserInvitation } from "services/userManagementAPIs";
import UserCreateUpdateForm from "./UserCreateUpdateForm";
import Button from "components/base/button/Button";
import { useTranslation } from "react-i18next";
import { MenuComponent } from "assets/ts/components";
import ConfirmationDelete from "components/base/ConfirmationDelete";
import { Modal } from "bootstrap";
import { UserDetails } from "helpers/models/userManagemnt";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import { useAuth } from "hooks‬/Auth";
import UserRoleExpandableDetails from "components/base/Expandable-Table/UserRoleExpandableDetails";
import ExpandableToggle from "components/base/Expandable-Table/ExpandableToggle";

const MenuTrigger = ({
  guid,
  deleteUser,
  editUser,
  inviteUser,
  isInvited,
  loggedEmail,
  validateEmail,
}: {
  guid: string;
  deleteUser: () => void;
  editUser: () => void;
  inviteUser?: () => void;
  isInvited: boolean;
  loggedEmail: string | undefined;
  validateEmail: string | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
      data-kt-menu="true"
    >
      <button
        className="menu-item px-3 border-0 bg-transparent"
        onKeyDown={() => editUser()}
        onClick={() => editUser()}
      >
        <span className="menu-link px-3 pointer">{t("EDIT")}</span>
      </button>

      {loggedEmail !== validateEmail && (
        <div
          className="menu-item px-3"
          data-bs-toggle="modal"
          data-bs-target="#delete_branch"
          onKeyDown={() => {
            deleteUser();
          }}
          onClick={() => {
            deleteUser();
          }}
        >
          <span
            data-kt-subscriptions-table-filter="delete_row"
            className="menu-link px-3"
          >
            {t("DELETE")}
          </span>
        </div>
      )}
      {!isInvited && inviteUser && (
        <div
          className="menu-item px-3"
          onKeyDown={() => {
            inviteUser();
          }}
          onClick={() => {
            inviteUser();
          }}
        >
          <span
            data-kt-subscriptions-table-filter="delete_row"
            className="menu-link px-3"
          >
            {t("INVITE")}
          </span>
        </div>
      )}
    </div>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  const { fetchAllUserHandler, allUsers, setAllUsers } =
    useUserManagementContext();
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState<UserDetails>();
  const confirmationModalRef = useRef<HTMLDivElement | null>(null);
  const [deleteUserGuid, setDeleteUserGuid] = useState("");
  const [expandedBranch, setExpandedBranch] = useState<number | null>(null);
  const [shouldShowCreateUpdateUser, setShouldShowCreateUpdateUser] =
    useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const userDeleteHandler = async (guid: string) => {
    await deleteUser(guid)
      .then((response) => {
        if (response.data.status === ERROR_STATUS_KEY) {
          setErrorMessage(t(response.data.statusDesc));
        } else {
          const updatedUsers = allUsers.filter((item) => item.guid !== guid);
          setAllUsers(updatedUsers);
        }
        if (confirmationModalRef.current) {
          const myModal = Modal.getInstance(confirmationModalRef.current);
          myModal?.hide();
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchAllUserHandler();
    // eslint-disable-next-line
  }, []);

  const toggleExpand = (index: number) => {
    if (expandedBranch === index) {
      setExpandedBranch(null);
    } else {
      setExpandedBranch(index);
    }
  };
  const resendUserInvitationHandler = async (guid: string) => {
    // try {
    await resendUserInvitation(guid)
      .then((response: any) => {
        console.log(response);
      })
      .catch((error) => console.error(error));
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [shouldShowCreateUpdateUser, allUsers]);
  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label={t("DASHBOARD")} />
        <BreadcrumbDirective label={t("USERS")} />
      </Breadcrumbs>

      <div className="card p-10  my-5">
        <CardHeader>
          <h3>{t("USERS")}</h3>
          <div className="  my-4">
            {shouldShowCreateUpdateUser ? (
              <span
                onClick={() => {
                  setIsUpdate(false);
                  setShouldShowCreateUpdateUser(false);
                }}
                onKeyDown={() => {
                  setIsUpdate(false);
                  setShouldShowCreateUpdateUser(false);
                }}
                className="btn btn-sm btn-primary bg-primary-text rounded-pill m-0  pointer"
              >
                <KTIcon iconName="plus" className="fs-2 bg-primary-text" />{" "}
                <span> {t("ADD.USER")}</span>
              </span>
            ) : (
              <Button
                classes="btn-sm rounded-pill btn-danger"
                onClick={() => {
                  setShouldShowCreateUpdateUser(true);
                }}
                onKeyDown={() => {
                  setShouldShowCreateUpdateUser(true);
                }}
              >
                <span className=" text-uppercase"> {t("CANCEL")}</span>
              </Button>
            )}
          </div>
        </CardHeader>

        <div className="card-body mt-5 p-0">
          {shouldShowCreateUpdateUser ? (
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              {errorMessage.length > 0 && (
                <ErrorMessageBox massage={errorMessage} />
              )}
              <Table>
                <TheadRow>
                  <th className="min-w-125px sorting">{t("FIRST.NAME")}</th>
                  <th className="min-w-125px sorting">{t("LAST.NAME")}</th>
                  <th className="min-w-125px sorting">{t("CONTACT.NUMBER")}</th>
                  <th className="min-w-125px sorting">{t("EMAIL")}</th>
                  <th className="min-w-125px sorting">{t("STATUS")}</th>
                  <th className="min-w-75px sorting">{t("ACTION")}</th>
                </TheadRow>
                <tbody className="fw-semibold">
                  {allUsers.map((items, index) => {
                    const isExpanded = expandedBranch === index;
                    return (
                      <Fragment key={items.guid}>
                        <tr>
                          <td className="d-flex gap-4">
                            <ExpandableToggle
                              isExpanded={isExpanded}
                              toggleExpand={() => {
                                toggleExpand(index);
                              }}
                            />
                            {items.firstName}
                          </td>
                          <td>{items.lastName}</td>
                          <td>{items.mobileNumber}</td>
                          <td>{items.email}</td>
                          <td>{items.status}</td>
                          <td>
                            <div>
                              <div
                                className="text-primary-white rounded-pill pointer"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                              >
                                <i className="fa-solid fa-ellipsis text-primary-white fs-2"></i>
                              </div>
                              <MenuTrigger
                                editUser={() => {
                                  setSelectedUserDetails(items);
                                  setIsUpdate(true);
                                  setShouldShowCreateUpdateUser(
                                    !shouldShowCreateUpdateUser
                                  );
                                }}
                                deleteUser={() => setDeleteUserGuid(items.guid)}
                                inviteUser={() => {
                                  resendUserInvitationHandler(items.guid);
                                }}
                                isInvited={items.status === "Active"}
                                guid={items.guid}
                                loggedEmail={userDetails?.email}
                                validateEmail={items.email}
                              />
                            </div>
                          </td>
                        </tr>
                        {isExpanded && (
                          <UserRoleExpandableDetails items={items} />
                        )}
                      </Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <UserCreateUpdateForm
              userDetails={isUpdate ? selectedUserDetails : undefined}
              fetchAllUserHandler={fetchAllUserHandler}
              setShouldShowCreateUpdateUser={setShouldShowCreateUpdateUser}
            />
          )}
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex={-1}
        id="delete_branch"
        ref={confirmationModalRef}
      >
        <ConfirmationDelete
          guid={deleteUserGuid}
          deleteItemHandler={userDeleteHandler}
        />
      </div>
      <ExportModal modalId="n_one_subscriptions_export_modal" />
    </div>
  );
};

export default Index;
