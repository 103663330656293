import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTIcon, WithChildren } from "../../../helpers";
import { useLayout } from "../../../layout/core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;

  return (
    <div className="menu-item">
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet text-dark">
            <span
              className={clsx(
                "bullet bullet-dot fs-6",
                { "text-white": isActive },
                { "bullet-color": !isActive }
              )}
            ></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            {" "}
            <KTIcon
              iconName={icon}
              className={clsx(
                "fs-2",

                { "text-white": isActive },
                { "text-dark": !isActive }
              )}
            />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span
          className={clsx(
            "menu-title ",
            { "text-white": isActive },
            { "text-dark": !isActive }
          )}
        >
          {title}{" "}
        </span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
