import InputTextField from "components/forms/InputTextField";
import { useEffect, useRef, useState } from "react";
import Button from "components/base/button/Button";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import Breadcrumbs from "components/base/breadcrumbs";
import CardHeader from "components/cards/CardHeader";
import { initThemeColor, themesColorData } from "constant/commonConstant";
import PredefinedThemes from "components/theme/PredefinedThemes";
import ThemeColorPicker from "components/theme/ThemeColorPicker";
import { useTranslation } from "react-i18next";
import {
  updateCompanyRebranding,
  getCompanyRebranding,
} from "services/companyManagementRequests";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import FormButton from "components/forms/FormButton";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import clsx from "clsx";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import { updateColors } from "components/theme/ThemeHelpers";
const initialValues = {
  urlPath: "",
  canonicalName: "",
  canonicalNameValue: "",
  themeColor: "",
};

const Index = () => {
  const { t } = useTranslation();
  const { userPreference, setUserPreference } = useUserManagementContext();
  const [isSaveValue, setIsSaveValue] = useState(true);
  let isSaved = useRef(isSaveValue);
  const [currentLogo, setCurrentLogo] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [rebrandingInputs, setRebrandingInputs] = useState(initialValues);
  const [rebrandingData, setRebrandingData] = useState(initialValues);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentBaseColor, setCurrentBaseColor] = useState<string | undefined>(
    ""
  );

  const handleRebrandingInputsChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setRebrandingInputs((values) => ({ ...values, [name]: value }));
  };

  function handleChange(event: any) {
    setSelectedImage(event.target.files[0]);
  }

  useEffect(() => {
    if (currentBaseColor)
      setRebrandingInputs((values) => ({
        ...values,
        themeColor: currentBaseColor,
      }));
  }, [currentBaseColor]);

  useEffect(() => {
    // try {
    getCompanyRebranding()
      .then((response: any) => {
        setRebrandingInputs(response.data.data);
        setRebrandingData(response.data.data);
        setCurrentLogo(response.data.data.companyLogo);
        if ("#" === response.data.data.themeColor.charAt(0)) {
          setCurrentBaseColor(response.data.data.themeColor);
        } else {
          setCurrentBaseColor("#" + response.data.data.themeColor);
        }
      })
      .catch((error) => console.error(error));
    // } catch (error) {
    //   console.error(error);
    // }

    return () => {
      if (isSaved.current) {
        callToUpdateTheme();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isSaved.current = isSaveValue;
  }, [isSaveValue]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    const jsonData = JSON.stringify(rebrandingInputs);

    const blob = new Blob([jsonData], { type: "application/json" });

    const formData = new FormData();
    formData.append("rebranding", blob);

    const imageFile = selectedImage;
    if (imageFile) {
      formData.append("companyLogo", imageFile);
    }
    formData.append("Content-Type", "application/json");
    updateCompanyRebranding(formData)
      .then((response) => {
        if (response.data.status === ERROR_STATUS_KEY) {
          setErrorMsg(response.data.statusDesc);
        } else {
          setRebrandingData(rebrandingInputs);
          setLoading(false);
          setIsDisable(true);
          setIsSaveValue(false);
          setUserPreference((previousData) => ({
            ...previousData,
            themeColor: rebrandingData.themeColor,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function callToUpdateTheme() {
    if (userPreference.themeColor) {
      if (userPreference.themeColor.startsWith("#")) {
        updateColors(userPreference.themeColor);
      } else {
        updateColors("#" + userPreference.themeColor);
      }
    }
  }

  const cancelToUpdate = () => {
    setIsDisable(true);
    callToUpdateTheme();
    setRebrandingInputs(rebrandingData);
  };

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label={t("DASHBOARD")} />
        <BreadcrumbDirective label={t("COMPANY.REBRANDING")} />
      </Breadcrumbs>

      <div className="card p-10  my-5">
        <form noValidate onSubmit={handleSubmit}>
          <CardHeader>
            <h3 className="my-4">{t("COMPANY.REBRANDING")}</h3>

            {isDisable ? (
              <div className="m-4">
                <Button
                  type="button"
                  classes="btn-sm rounded-pill"
                  onClick={() => setIsDisable(false)}
                  loading={loading}
                  disabled={loading}
                >
                  <span className=" text-uppercase"> {t("EDIT")}</span>
                </Button>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <FormButton
                  btnLabel={t("SAVE")}
                  classes="btn-sm rounded-pill my-0"
                  isBtnDisabled={loading}
                  loading={loading}
                />{" "}
                <div className="  my-4">
                  <Button
                    classes="btn-sm rounded-pill btn-danger"
                    type="button"
                    onClick={(e) => {
                      cancelToUpdate();
                    }}
                    disabled={loading}
                  >
                    <span className=" text-uppercase"> {t("CANCEL")}</span>
                  </Button>
                </div>
              </div>
            )}
          </CardHeader>
          {errorMsg.length > 0 && <ErrorMessageBox massage={errorMsg} />}

          <div className="row my-4 justify-content-between fs-6  ">
            <div className="col-12 col-md-6 col-xxl-5">
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                  {t("URL.PATH")}
                </div>
                <div className="col-8">
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("URL.PATH")}
                    id="urlPath"
                    name="urlPath"
                    value={rebrandingInputs.urlPath || ""}
                    onChange={handleRebrandingInputsChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                  {t("CANONICAL.NAME")}
                </div>
                <div className="col-8">
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("CANONICAL.NAME")}
                    id="canonicalName"
                    name="canonicalName"
                    value={rebrandingInputs.canonicalName || ""}
                    onChange={handleRebrandingInputsChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                  {t("CANONICAL.NAME.VALUE")}
                </div>
                <div className="col-8">
                  <InputTextField
                    isDisable={isDisable}
                    type="text"
                    placeholder={t("CANONICAL.NAME.VALUE")}
                    id="canonicalNameValue"
                    name="canonicalNameValue"
                    value={rebrandingInputs.canonicalNameValue || ""}
                    onChange={handleRebrandingInputsChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                  {t("COMPANY.LOGO")}
                </div>
                <div className="col-8 d-flex flex-column justify-content-center">
                  {
                    <div className="mb-2">
                      <img
                        src={
                          selectedImage
                            ? URL.createObjectURL(selectedImage)
                            : currentLogo
                        }
                        alt={currentLogo}
                        style={{ width: "100px", height: "auto" }}
                      />
                    </div>
                  }
                  <input type="file" accept="image/*" onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="border-1 border-bottom border-gray-400">
          <div className="my-2 fs-16 text-muted">{t("THEMES")}</div>
        </div>
        <div
          className={clsx(
            "d-flex justify-content-start align-items-center my-4 gap-4 flex-wrap ",
            isDisable && "pe-none"
          )}
        >
          <div className="mw-700px">
            <div className="p-1 radius-12 d-flex flex-wrap gap-2 justify-content-center border with-auto">
              {themesColorData.map((theme, index) => {
                return (
                  <PredefinedThemes
                    key={theme.baseColor}
                    currentBaseColor={currentBaseColor}
                    setCurrentBaseColor={setCurrentBaseColor}
                    baseColor={theme.baseColor}
                    activeColor={theme.activeColor}
                    lightColor={theme.lightColor}
                  />
                );
              })}
              <ThemeColorPicker
                currentBaseColor={currentBaseColor}
                setCurrentBaseColor={setCurrentBaseColor}
                id_color_type="theme_color_picker"
              />
            </div>
          </div>
          <div>
            <button
              className="btn bg-primary rounded-pill radius-12 d-flex align-items-center justify-content-center pointer"
              onClick={() => {
                setCurrentBaseColor(initThemeColor.PRIMARY);
              }}
              disabled={isDisable}
            >
              <label className="me-2 bg-primary-text">{t("RESET")}</label>
              <i
                className="fa-solid fa-arrow-rotate-left fs-2 bg-primary-text"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
