import FormButton from "components/forms/FormButton";
import InputTextField from "components/forms/InputTextField";
import SearchableDropdown from "components/forms/SearchableDropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useAuthValidationRules,
  useDropdownValidationRules,
} from "helpers/validation-schema/AuthValidationRules";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocationContext } from "hooks‬/LocationContext";
import { createUser, updateUserFromAllUser } from "services/userManagementAPIs";
import { SelectionOption } from "helpers/models/auth_models";
import { UserDetails } from "helpers/models/userManagemnt";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import { statusGetter } from "helpers/common/genericFunction";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import { getProducts } from "services/companyManagementRequests";
import { useCommonContext } from "hooks‬/CommonContext";
interface Props {
  userDetails?: UserDetails;
  fetchAllUserHandler: () => void;
  setShouldShowCreateUpdateUser: Dispatch<SetStateAction<boolean>>;
}
const UserCreateUpdateForm: FC<Props> = ({
  userDetails,
  fetchAllUserHandler,
  setShouldShowCreateUpdateUser,
}) => {
  const { fetchUserRoleHandler, userRole } = useUserManagementContext();
  const { showSuccessToast } = useCommonContext();
  const { t } = useTranslation();
  const [filterUserRole, setFilterUserRole] = useState<SelectionOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [productOption, setProductOption] = useState<SelectionOption[]>([]);

  const [selectedRole, setSelectedRole] = useState("");
  const [assignedRole, setAssignedRole] = useState<
    { displayName: string; guid: string; productName: string }[]
  >([]);
  const {
    fetchCountries,
    countries,
    fetchStates,
    states,
    fetchCities,
    cities,
    setStates,
    setCities,
  } = useLocationContext();
  const validationRules = useAuthValidationRules();
  const handleProducts = async () => {
    try {
      await getProducts()
        .then((response: any) => {
          console.log(response);
          if (response.data.status === "ERROR") {
            console.log(response.data.statusDesc);
          } else {
            setProductOption(
              response.data.data.map((item: any) => ({
                label: item.productTypeDTO.displayName,
                value: item.productTypeDTO.guid,
              }))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleProducts();
  }, []);

  interface City {
    guid: string;
    displayName: string;
    state: {
      guid: string;
      displayName: string;
      country: {
        guid: string;
        displayName: string;
      };
    };
  }

  interface UserRole {
    guid: string;
    roleName?: string;
    displayName?: string;
    displayOrder?: number;
    productType?: {
      guid?: string;
      name?: string;
      displayName?: string;
    };
  }

  interface InitUserDetails {
    guid: string;
    firstName: string;
    lastName: string;
    fullName: string;
    mobileNumber: string;
    email: string;
    address1: string;
    address2: string;
    status: string;
    pinCode: string;
    city: City;
    userRoles?: UserRole[];
    systemAdmin: boolean;

    product_guid: string;
  }

  const initialValues: InitUserDetails = {
    guid: "",
    firstName: "",
    lastName: "",
    fullName: "",
    mobileNumber: "",
    email: "",
    address1: "",
    address2: "",
    status: "",
    pinCode: "",
    product_guid: "",
    userRoles: [
      {
        guid: "",
      },
    ],
    city: {
      guid: "",
      displayName: "",
      state: {
        guid: "",
        displayName: "",
        country: {
          guid: "",
          displayName: "",
        },
      },
    },
    systemAdmin: false,
  };

  const stateHandler = async (guid: string | undefined) => {
    formik.setFieldValue("city.state.guid", "");
    formik.setFieldValue("city.guid", "");
    fetchStates(guid);
  };

  const cityHandler = async (guid: string | undefined) => {
    formik.setFieldValue("city.guid", "");
    fetchCities(guid);
  };

  const userSchema = Yup.object().shape({
    firstName: validationRules.nameValidationSchema,
    lastName: validationRules.nameValidationSchema,
    email: validationRules.emailValidationSchema,
    mobileNumber: validationRules.contactNumberValidationSchema,
    address1: validationRules.address1ValidationSchema,
    address2: validationRules.address2ValidationSchema,
    pinCode: validationRules.pinCodeValidationSchema,
    city: Yup.object().shape({
      guid: useDropdownValidationRules(cities),
      state: Yup.object().shape({
        guid: useDropdownValidationRules(states),
        country: Yup.object().shape({
          guid: useDropdownValidationRules(countries),
        }),
      }),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let status = statusGetter(values.status);
      const userData: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        isSystemAdmin: values.systemAdmin,
        mobileNumber: values.mobileNumber,
        address1: values.address1,
        address2: values.address2,
        pinCode: values.pinCode,
        cityGuid: values.city.guid,
        status: status,
      };

      if (userDetails) {
        await updateUserFromAllUser(userData, userDetails.guid)
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              formik.setStatus(t(response.data.statusDesc));
              setSubmitting(false);
            } else {
              setShouldShowCreateUpdateUser(true);
              fetchAllUserHandler();
              showSuccessToast("User updated Successfully");
            }
          })
          .catch((error) => console.log(error));
      } else {
        if (assignedRole.length > 0) {
          userData.userRoles = assignedRole.map((item) => ({
            guid: item.guid,
          }));
        }
        await createUser(userData)
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              formik.setStatus(t(response.data.statusDesc));
              setSubmitting(false);
            } else {
              setSubmitting(false);
              setShouldShowCreateUpdateUser(true);
              fetchAllUserHandler();
              showSuccessToast("User Added Successfully");
            }
          })
          .catch((error) => console.error(error));
      }
    },
  });
  const didRequest = useRef(false);
  useEffect(() => {
    if (
      countries.length > 0 &&
      states.length > 0 &&
      cities.length > 0 &&
      userDetails?.city &&
      !didRequest.current
    ) {
      if (userDetails.userRoles) {
        setAssignedRole(
          userDetails?.userRoles.map((item: any) => {
            return {
              displayName: item.displayName,
              guid: item.guid,
              productName: item.productType.name,
            };
          })
        );
      }
      formik.setValues({ ...initialValues, ...userDetails });
      didRequest.current = true;
    }
    // eslint-disable-next-line
  }, [countries, states, cities]);

  useEffect(() => {
    fetchCountries();
    if (
      userDetails?.city &&
      userDetails?.city?.state &&
      userDetails?.city?.state.country
    ) {
      stateHandler(userDetails?.city.state?.country.guid);
      cityHandler(userDetails.city.state?.guid);
    } else if (userDetails) {
      formik.setValues({ ...initialValues, ...userDetails });
    } else {
      formik.setValues(initialValues);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  useEffect(() => {
    if (userRole.length > 0) {
      const filteredRoles = userRole.filter(
        (item) => item.productType.guid === selectedProduct
      );

      setFilterUserRole(
        filteredRoles.map((filteredItem: any) => ({
          label: filteredItem.displayName,
          value: filteredItem.guid,
        }))
      );
    }
    // eslint-disable-next-line
  }, [selectedProduct]);

  useEffect(() => {
    fetchUserRoleHandler();
    return () => {
      setStates([]);
      setCities([]);
    };
    // eslint-disable-next-line
  }, []);

  const handleToAssignedRole = ({
    displayName,
    guid,
  }: {
    displayName: string;
    guid: string;
  }) => {
    setSelectedRole(guid);
    const matchedRoleList = userRole.find((role: any) => role.guid === guid);
    if (matchedRoleList && assignedRole.length > 0) {
      let isExist: boolean = false;
      let UpdateRoleIndex = 0;

      for (let index = 0; index < assignedRole.length; index++) {
        const roleItem = assignedRole[index];
        const matchedRoleListA = userRole.find(
          (role: any) => role.guid === roleItem.guid
        );
        if (
          matchedRoleList.productType.guid === matchedRoleListA.productType.guid
        ) {
          UpdateRoleIndex = index;
          isExist = true;
          break;
        }
      }
      if (isExist) {
        setAssignedRole((prevRoles) => {
          const updatedRoles = [...prevRoles];
          updatedRoles[UpdateRoleIndex] = {
            displayName,
            guid,
            productName: matchedRoleList.productType.name,
          };
          return updatedRoles;
        });
      } else {
        setAssignedRole((role) => [
          ...role,
          {
            displayName,
            guid,
            productName: matchedRoleList.productType.name,
          },
        ]);
      }
    } else {
      setAssignedRole((role) => [
        ...role,
        {
          displayName,
          guid,
          productName: matchedRoleList.productType.name,
        },
      ]);
    }
  };

  const removeRole = (guidToDelete: string) => {
    setAssignedRole((prevRoles) =>
      prevRoles.filter((role) => role.guid !== guidToDelete)
    );
  };

  useEffect(() => {
    setSelectedRole("");
  }, [selectedProduct]);
  useEffect(() => {
    formik.setFieldValue("userRoles", [
      ...assignedRole.map((item: any) => ({
        guid: item.guid,
      })),
    ]);
    // eslint-disable-next-line
  }, [assignedRole]);

  return (
    <div>
      {formik.status && <ErrorMessageBox massage={formik.status} />}
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="container">
          <div className="row my-4 justify-content-between fs-6  ">
            <div className="col-12 col-md-6 col-xxl-5">
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex align-items-center required">
                  {t("FIRST.NAME")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("FIRST.NAME")}
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("LAST.NAME")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("LAST.NAME")}
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("ADDRESS1")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("ADDRESS1")}
                    id="address1"
                    name="address1"
                    value={formik.values.address1 || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address1 && Boolean(formik.errors.address1)
                    }
                    helperText={
                      formik.touched.address1 && formik.errors.address1
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                  {t("ADDRESS2")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("ADDRESS2")}
                    id="address2"
                    name="address2"
                    value={formik.values.address2 || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address2 && Boolean(formik.errors.address2)
                    }
                    helperText={
                      formik.touched.address2 && formik.errors.address2
                    }
                  />
                </div>
              </div>
              {!userDetails && (
                <div className="row">
                  <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                    {t("EMAIL")}
                  </div>
                  <div className="col-8">
                    <InputTextField
                      type="text"
                      placeholder={t("EMAIL")}
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("CONTACT.NUMBER")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("CONTACT.NUMBER")}
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formik.values.mobileNumber || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobileNumber &&
                      Boolean(formik.errors.mobileNumber)
                    }
                    helperText={
                      formik.touched.mobileNumber && formik.errors.mobileNumber
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-5">
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("COUNTRY")}
                </div>
                <div className="col-8">
                  <SearchableDropdown
                    placeholder={t("COUNTRY")}
                    name="city.state.country.guid"
                    value={formik.values.city?.state.country.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={countries}
                    getSelectedOption={stateHandler}
                    error={
                      formik.touched?.city?.state?.country?.guid &&
                      Boolean(formik.errors.city?.state?.country?.guid)
                    }
                    helperText={
                      formik.touched.city?.state?.country?.guid &&
                      formik.errors.city?.state?.country?.guid
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("STATE")}
                </div>
                <div className="col-8">
                  <SearchableDropdown
                    getSelectedOption={cityHandler}
                    placeholder={t("STATE")}
                    name="city.state.guid"
                    value={formik.values.city.state.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={states}
                    error={
                      formik.touched.city?.state?.guid &&
                      Boolean(formik.errors.city?.state?.guid)
                    }
                    helperText={
                      formik.touched.city?.state?.guid &&
                      formik.errors.city?.state?.guid
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("CITY")}
                </div>
                <div className="col-8">
                  <SearchableDropdown
                    placeholder={t("CITY")}
                    name="city.guid"
                    value={formik.values.city.guid || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    selectionOptions={cities}
                    error={
                      formik.touched.city?.guid &&
                      Boolean(formik.errors.city?.guid)
                    }
                    helperText={
                      formik.touched.city?.guid && formik.errors.city?.guid
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center required">
                  {t("PIN.CODE")}
                </div>
                <div className="col-8">
                  <InputTextField
                    type="text"
                    placeholder={t("PIN.CODE")}
                    id="pinCode"
                    name="pinCode"
                    value={formik.values.pinCode || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.pinCode && Boolean(formik.errors.pinCode)
                    }
                    helperText={formik.touched.pinCode && formik.errors.pinCode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="d-flex    py-5 pb-0 fs-3">
                  <label className="text-muted fs-6 ">
                    {t("Grant admin access?")}
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="is-reseller"
                      value="Y"
                      checked={formik.values.systemAdmin === true}
                      onChange={() => {
                        formik.setValues({
                          ...formik.values,
                          systemAdmin: true,
                        });
                      }}
                      className="mx-5"
                    />{" "}
                    {t("YES")}
                    <input
                      type="radio"
                      name="is-reseller"
                      checked={formik.values.systemAdmin === false}
                      onChange={() => {
                        formik.setValues({
                          ...formik.values,
                          systemAdmin: false,
                        });
                      }}
                      value="N"
                      className="mx-5"
                    />{" "}
                    {t("NO")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!userDetails && (
          <>
            <div className="border-top  py-5">{t("ASSIGN.ROLE")}</div>
            <div className="row   justify-content-between fs-6">
              <div className="col-12  col-sm-6 col-md-6 col-xxl-5  p-2">
                <div>
                  <div className="row">
                    <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center ">
                      {t("PRODUCT")}
                    </div>
                    <div className="col-8">
                      <SearchableDropdown
                        placeholder={t("PRODUCT")}
                        name="product_guid"
                        value={selectedProduct}
                        onChange={(event) => {
                          setSelectedProduct(event.target.value);
                        }}
                        selectionOptions={productOption}
                        error={false}
                        helperText={""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 mb-4 fs-16 text-muted d-flex   align-items-center">
                      {t("Role")}
                    </div>
                    <div className="col-8">
                      <SearchableDropdown
                        placeholder={t("USER.ROLE")}
                        name="selectedRole"
                        value={selectedRole}
                        onChange={(event) => {
                          const guid = event.target.value;
                          const displayName: string = event.target.label;
                          handleToAssignedRole({ guid, displayName });
                        }}
                        selectionOptions={filterUserRole}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>
              <div className="col-12 col-md-6 col-xxl-5 border-1  rounded border p-2 border-1">
                <div className="d-flex justify-content-start flex-wrap ">
                  {assignedRole.map((item) => (
                    <div
                      key={item.guid}
                      className="bg-secondary  p-4 py-2  m-2 rounded-pill "
                    >
                      <span
                        className="fs-4 text-dark"
                        style={{ paddingRight: "10px" }}
                      >
                        {item.productName}-{item.displayName}
                      </span>
                      <span
                        className="pointer mx-2 "
                        onKeyDown={() => removeRole(item.guid)}
                        onClick={() => removeRole(item.guid)}
                      >
                        <i className="fa-solid fa-x text-dark"></i>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row justify-content-end ">
          <div className="col-4">
            <FormButton
              btnLabel={userDetails ? t("UPDATE") : t("ADD.USER")}
              loading={formik.isSubmitting}
              isBtnDisabled={formik.isSubmitting}
              classes="rounded-pill "
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserCreateUpdateForm;
