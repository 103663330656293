import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeModeComponent } from "../../../assets/ts/layout";

export type ThemeModeType = "dark" | "light" | "system";
export const themeModelSKey = "theme_mode_value";
export const themeMenuModeLSKey = "theme_mode_menu";

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

type ThemeModeContextType = {
  mode: ThemeModeType;
  menuMode: ThemeModeType;
  updateMode: (_mode: ThemeModeType) => void;
  updateMenuMode: (_mode: ThemeModeType) => void;
};

const themeModeSwitchHelper = (_mode: ThemeModeType) => {
  // change background image url
  // const mode = _mode !== "system" ? _mode : systemMode;
};

const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  if (!localStorage) {
    return "light";
  }

  const data = localStorage.getItem(lsKey);
  if (data === "dark" || data === "light" || data === "system") {
    return data;
  }

  return "light";
};

const defaultThemeMode: ThemeModeContextType = {
  mode: getThemeModeFromLocalStorage(themeModelSKey),
  menuMode: getThemeModeFromLocalStorage(themeMenuModeLSKey),
  updateMode: (_mode: ThemeModeType) => {},
  updateMenuMode: (_menuMode: ThemeModeType) => {},
};

const ThemeModeContext = createContext<ThemeModeContextType>({
  mode: defaultThemeMode.mode,
  menuMode: defaultThemeMode.menuMode,
  updateMode: (_mode: ThemeModeType) => {},
  updateMenuMode: (_menuMode: ThemeModeType) => {},
});

const useThemeMode = () => useContext(ThemeModeContext);

const ThemeModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [mode, setMode] = useState<ThemeModeType>(defaultThemeMode.mode);
  const [menuMode, setMenuMode] = useState<ThemeModeType>(
    defaultThemeMode.menuMode
  );

  const updateMode = (
    _mode: ThemeModeType,
    saveInLocalStorage: boolean = true
  ) => {
    setMode(_mode);
    // themeModeSwitchHelper(updatedMode)
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeModelSKey, "light");
    }

    // if (saveInLocalStorage) {
    //   const updatedMode = _mode === "system" ? systemMode : _mode;
    //   document.documentElement.setAttribute("data-bs-theme", updatedMode);

    //   let root = document.documentElement;
    //   if (updatedMode === "light") {
    //     root.style.setProperty("--bs-app-bg-color", "var(--primary-light");
    //   }
    //   if (updatedMode === "dark") {
    //     root.style.setProperty("--bs-app-bg-color", "#0b0b0b");
    //   }
    // }
    ThemeModeComponent.init();
  };

  const updateMenuMode = (
    _menuMode: ThemeModeType,
    saveInLocalStorage: boolean = true
  ) => {
    setMenuMode(_menuMode);
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeMenuModeLSKey, "light");
    }
  };

  useEffect(() => {
    updateMode(mode);
    updateMenuMode(menuMode, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({
      mode,
      menuMode,
      updateMode,
      updateMenuMode,
    }),
    // eslint-disable-next-line
    [mode, menuMode]
  );
  return (
    <ThemeModeContext.Provider value={contextValue}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export { ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper };
