import { FC } from "react";
import { Link } from "react-router-dom";
import profile from "assets/images/Profile.png";
import { useAuth } from "hooks‬/Auth";
import { useTranslation } from "react-i18next";
const HeaderUserMenu: FC = () => {
  const { t } = useTranslation();
  const { userDetails, systemLogout } = useAuth();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={profile} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {userDetails?.fullName}
            </div>
            <p className="fw-bold text-muted text-hover-primary fs-7">
              {userDetails?.email}
            </p>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={"/my-profile"} className="menu-link px-5">
          {t("MY.PROFILE")}
        </Link>
      </div>

      <div className="separator my-2"></div>

      <div
        className="menu-item px-5"
        onClick={() => {
          systemLogout();
        }}
      >
        <div className="menu-link px-5">{t("LOGOUT")}</div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
