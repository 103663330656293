// import Button from "../../base/button/Button";
// import AccessButtonIcon from "./AccessButtonIcon";
// import ShowDetailsCard from "./ShowDetailsCard";
// import { useThemeMode } from "hooks‬/theme";
import atsLogo from "assets/svg/ats-logo.svg";
import { KTIcon } from "helpers";
const { REACT_APP_ATS_URL } = process.env;
const Index = ({
  productTitle,
  productDescription,
  isBought = true,
}: {
  productTitle: string;
  productDescription: string;
  isBought?: boolean;
}) => {
  // const { mode } = useThemeMode();

  const handleClick = () => {
    if (isBought) {
      window.open(REACT_APP_ATS_URL, "WindowName", "noopener");
    }
  };

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4  col-xxl-3   my-4 ">
      <div className="card p-5 rounded-0 border-bottom border-4 border-primary">
        <div>
          <img src={atsLogo} alt="logo" />
        </div>
        <h1 className="py-2">{productTitle}</h1>
        <div className="pb-10">{productDescription}</div>
        <div>
          <button
            className={`access-product-btn text-primary fs-2 `}
            disabled={!isBought}
            onClick={handleClick}
          >
            ACCESS <KTIcon iconName="right" className="fs-2 text-primary" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
